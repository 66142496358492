import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Copyright from './copyright';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProjectCard = ({...props}) => {
  const { title, content } = props;
  const { category, location, status} = props.project_data;
  const thumbnail = getImage(props.thumbnail.src.childImageSharp.gatsbyImageData);
  const heroImage = getImage(props.hero_image.src.childImageSharp.gatsbyImageData);
  const compImage = getImage(props.comp_image.src.childImageSharp.gatsbyImageData);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return ( 
    <>
    <div className="project-card" onClick={()=>setModalIsOpen(!modalIsOpen)}>
      <div className="project-title">
        { title }
      </div>
      <div className="project-thumbnail">
        <GatsbyImage image={thumbnail}
        alt={`${title}-thumbnail`}/>
      </div>
    </div>
    <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        contentLabel="example"
        style={{
          content: {
            inset: `1.5rem`,
            borderRadius: `0`,
            padding: `0`,
          },
        }}
      >
      <button onClick={()=> setModalIsOpen(!modalIsOpen)} className="btn-modal btn-close btn-top-right">
        <FontAwesomeIcon icon={faTimes}/>
      </button>
      <div className="card-content">
        <div className="modal-hero">
          <GatsbyImage
            image={heroImage}
            alt={`${title}-landing`}
          />
        </div>
        <div className="project-info">
          <h1>{title}</h1>
          <p>
            <span className="strong">Category </span><span> : {category.map((ctg, index) => {return(<span className="cat" key={index}>{ctg}</span>)})}</span>
            <span className="strong">Location </span><span> : {location}</span>
            <span className="strong">Status </span><span> : {status}</span>
          </p>
        </div>
        <div className="project-description">
        <p>{content}</p>
          
          <div className="complementary-image">
            <GatsbyImage
              image={compImage}
              alt={`${title}-landing`}
            />
          </div>
        </div>
        
      </div>
      <Copyright/>
      </Modal>
    </>
   );
}
 
export default ProjectCard;