import React from 'react';
import DesktopLayout from './desktopLayout';
import { dskProjectsCardsWrapper, projectFilter } from "../../style/layout/_projects.module.scss";
import { dskPage, dskPageWrapper, dskPageTitle } from '../../style/layout/_page.module.scss';
import ProjectDesktopCard from '../cards/projectDesktopCard';
import { useState } from 'react';
import Copyright from '../copyright';

const DesktopProjectsLayout = ({...data}) => {
  const [categoryFilter, setCategoryFilter] = useState("ALL");
  const projectCategory = ["RESIDENTIAL", "ALL", "COMMERCIAL"];

  return ( 
  <DesktopLayout>
    <div className={dskPage}>
      <div className={dskPageWrapper}>
        <div className={dskPageTitle}>PROJECTS</div>
        <p>From residences to commercial spaces, from individual rooms to public areas.<br/>
        We provide interior and product design service over any kind of needs and stories.</p>
        <div className={projectFilter}>
          <form>
            {projectCategory.map((category, index) => {
              return (<label key={index}>
                <input
                type="radio"
                name="category-filter"
                value={category}
                checked={category === categoryFilter}
                onChange={()=>{setCategoryFilter(category)}}
                />
                <span>{category}</span>
              </label>)
            })}
          </form>
        </div>
        <div className={dskProjectsCardsWrapper}>
          {categoryFilter !== "ALL" ? 
          data.allProjectsJson.nodes.filter((project) => 
            project.project_data.category.includes(categoryFilter)
          )
          .map((project) => {
            return (<ProjectDesktopCard {...project} key={project.id}/>)
          })
          :
          data.allProjectsJson.nodes.map((project) => {
            return (<ProjectDesktopCard {...project} key={project.id}/>)
          })
          }
        </div>
      </div>
    </div>
    <Copyright/>
  </DesktopLayout>
  );
}
 
export default DesktopProjectsLayout;