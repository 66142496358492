import { graphql } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Page from "../components/page";
import ProjectCard from "../components/projectCard";
import { pageTitle } from "../style/layout/_page.module.scss";
import {
  projectFilter,
  projectContent,
} from "../style/layout/_projects.module.scss";
import { isMobile } from "react-device-detect";
import DesktopProjectsLayout from "../components/desktop/desktopProjectsLayout";

const Projects = ({ data }) => {
  const projects = data.allProjectsJson.nodes;
  const projectCategory = ["ALL", "RESIDENTIAL", "COMMERCIAL"];
  const [categoryFilter, setCategoryFilter] = useState("ALL");

  const handleFiltering = (e) => {
    setCategoryFilter(e.target.value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects | Teko Merah</title>
        <meta name="author" content="readmerisen" />
        <meta
          name="description"
          content="We cover residential projects as well as commercial ones.
          From your little kids bedroom to retail stores."
        />
        <meta
          name="twitter:title"
          content="Teko Merah Design Studio Projects"
        ></meta>
      </Helmet>
      {isMobile ?  <Page>
        <div className="projects-page page-content">
          <div className={pageTitle}>PROJECTS</div>
          <div className={projectFilter}>
            <form>
              {projectCategory.map((category, index) => {
                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="category-filter"
                      value={category.toUpperCase()}
                      checked={categoryFilter === category.toUpperCase()}
                      onChange={(e) => {
                        handleFiltering(e);
                      }}
                    />
                    <span>{category}</span>
                  </label>
                );
              })}
            </form>
          </div>
          <div className={projectContent}>
            {categoryFilter !== "ALL"
              ? projects
                  .filter((project) =>
                    project.project_data.category.includes(categoryFilter)
                  )
                  .map((project) => {
                    return <ProjectCard key={project.id} {...project} />;
                  })
              : projects.map((project) => {
                  return <ProjectCard key={project.id} {...project} />;
                })}
          </div>
        </div>
      </Page>
      :
      <DesktopProjectsLayout {...data} />
      }
    </>
  );
};

export const query = graphql`
  query {
    allProjectsJson {
      nodes {
        thumbnail {
          src {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
        id
        title
        content
        project_data {
          category
          location
          status
        }
        hero_image {
          src {
            childImageSharp {
              gatsbyImageData(width: 900)
            }
          }
          alt
        }
        comp_image {
          src {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
          alt
        }
      }
    }
  }
`;

export default Projects;
