import React, { useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { cardWrapper, card, captionWrapper, cardCaption,
backImage, projectLocation } from '../../style/components/_card.module.scss';
import { dskModalWrapper,
  dskModalCompImage, dskProjectCategory, dskProjectCategoryWrapper, dskContent,
  modalProjectLocation, btnClose, modalProject} from '../../style/components/_card.desktop.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

const ProjectDesktopCard = ({...project}) => {
  const {thumbnail, id, title, content, 
    project_data} = project;
  const heroImage = getImage(project.hero_image.src.childImageSharp.gatsbyImageData);
  const compImage = getImage(project.comp_image.src.childImageSharp.gatsbyImageData);
  const {category, location} = project_data;
  const cardWrap = useRef();
  const cardRef = useRef();
  const bgImage = useRef();
  
  const [modalIsOpen, setModalIsOpen] = useState(false);


  return (
    <>
      <div className={cardWrapper} ref={cardWrap}>
        <div
          className={card}
          ref={cardRef}
          onClick={()=> {setModalIsOpen(!modalIsOpen)}}>
          
          {/* image */}
          <div className={backImage} ref={bgImage}>
            <GatsbyImage
              image={thumbnail.src.childImageSharp.gatsbyImageData}
              alt={`${title} project`}
            />
          </div>

          {/* caption */}
          <div className={captionWrapper}>
            <div className={cardCaption}>
              <h3>{title.toUpperCase()}</h3>
              <div className={projectLocation}>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {location}
              </div>
            </div>
          </div>

        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        contentLabel="project"
        style={{
          content: {
            inset: `2rem 5rem`,
            borderRadius: `0`,
            padding: `0`,
            border: `0`,
            boxShadow: `5px 5px 10px 0px rgba(0,0,0,0.15)`,
          },
        }}
      >
        <div className={dskModalWrapper}>
          
            <div className={dskModalCompImage}>
              <GatsbyImage
              image={compImage}
              alt={`${title}-comp-image`}
              />
            </div>
          <div className={dskContent}>
            <h1>{title}</h1>
            <div className={modalProjectLocation}>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {location}
              </div>
            <div className={dskProjectCategoryWrapper}>
              {category.map((cat, index) => {
                return (
                  <div className={dskProjectCategory} key={index}>
                    {cat}
                  </div>
                )
              })}
            </div>
            
            <p>{content}</p>
            <div>
              <GatsbyImage
               image={heroImage}
               alt={`${title}`}/>
            </div>
          </div>
        </div>
        <button className={btnClose}
          onClick={()=>{setModalIsOpen(!modalIsOpen)}}
        ><FontAwesomeIcon icon={faTimes}/></button>
      </Modal>
    </>
  );
}
 
export default ProjectDesktopCard;